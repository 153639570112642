import { createStore } from "vuex";
import invoiceCurrency from "@/store/modules/invoice-currency";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    invoiceCurrency,
  },
});
