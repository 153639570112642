import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/invoice/classic",
    name: "ClassicTemplate",
    component: () =>
      import(
        /* webpackChunkName: "invoice-classic-template" */ "@/views/invoice-templates/Classic/ClassicTemplate.vue"
      ),
    meta: {
      title: "Classic Invoice",
    },
  },
  {
    path: "/invoice/simple",
    name: "SimpleTemplate",
    component: () =>
      import(
        /* webpackChunkName: "invoice-simple-template" */ "@/views/invoice-templates/Simple/SimpleTemplate.vue"
      ),
    meta: {
      title: "Simple Invoice",
    },
  },
];

export default routes;
