import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/select-template",
    name: "SelectTemplate",
    component: () =>
      import(
        /* webpackChunkName: "select-invoice-template" */ "@/views/invoice/SelectInvoiceTemplate.vue"
      ),
    meta: {
      title: "Select Invoice Template",
    },
  },
];

export default routes;
