import { createStore } from "vuex";
import { preferredCurrency, allCurrencies } from "@/utils/currency";

export default createStore({
  state: {
    selectedCurrency: { ...preferredCurrency },
    allCurrencies,
  },
  mutations: {
    handleInvoiceInput(state, payload) {
      Object.assign(state, payload);
    },
  },
  actions: {},
});
