import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultRoutes from "@/router/default-routes";
import InvoiceRoutes from "@/router/invoice-routes";
import InvoiceTemplatesRoutes from "@/router/invoice-templates-routes";

const routes: Array<RouteRecordRaw> = [
  ...DefaultRoutes,
  ...InvoiceRoutes,
  ...InvoiceTemplatesRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/* Default title tag */
const defaultDocumentTitle = "Invoice Fast";

router.afterEach((to) => {
  /* Set document title from route meta */
  if (to?.meta?.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

export default router;
